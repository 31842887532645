import React, { useRef, useState, useEffect } from "react";
import styles from "./index.module.css";
import { registerAllModules } from "handsontable/registry";
import apiClient from "../../../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import { HotTable } from "@handsontable/react";
import "handsontable/dist/handsontable.full.min.css";
import { Oval } from "react-loader-spinner";
import { MdOutlineUploadFile } from "react-icons/md";
import Popup from "reactjs-popup";
import { RxCross2 } from "react-icons/rx";

registerAllModules();

const overlayStyle = { background: "rgba(0,0,0,0.5)" };

const placeHolderData = [
  "R-19",
  "2020-2024",
  "AIML",
  "1",
  "Py123",
  "Python",
  "pp",
  "Theory",
  "60",
  "A,C",
];

const ExcelView = (props) => {
  const hotTableComponent = useRef(null);
  const [excelData, updateExcelData] = useState([
    [
      "Regulation",
      "Batch",
      "Branch",
      "Semester",
      "Course Code",
      "Course Name",
      "Course Short Name",
      "Course Structure Name",
      "Target Attainment",
      "Section List",
    ],
  ]);

  const [loading, updateLoading] = useState(false);
  const [popUp, updatePopup] = useState(false);
  const [errors, updateErrors] = useState([]);

  const { excelEntryBtn, courseCreatedExcel } = props;

  const preCol = [];

  placeHolderData.forEach((each, index) => {
    preCol.push({
      row: 1,
      col: index,
      placeholder: `Ex: ${each}`,
      placeholderCellClassName: styles["custom-handsontable-placeholder"],
    });
  });

  const highlightCells = (errCell) => {
    const hot = hotTableComponent.current.hotInstance;

    errCell.forEach(([row = 1, col = 1]) => {
      highlightCellByCoords(hot, row - 1, col - 1);
    });
  };

  const highlightCellByCoords = (hotInstance, row, col) => {
    hotInstance.setCellMeta(
      row,
      col,
      "className",
      `${styles["highlighted-cell"]}`
    );

    hotInstance.render();
  };

  const renderErrors = () => {
    let ans = [];

    try {
      for (let i = 0; i < errors.length; i++) {
        ans.push(
          <li key={i}>
            <span>{i + 1}.</span>
            {errors[i]}
          </li>
        );
      }
    } catch (err) {
      console.log(err);
    }

    return ans;
  };

  const addTemplateManually = async (fileData) => {
    try {
      updateLoading(true);
      updateErrors([]);

      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: fileData,
      };

      const response = await fetch(
        apiClient.urls.fatcat.INSERT_BULK_COURSES,

        options
      );

      if (response["ok"] === true) {
        const res = await response.json();
        if (res["statusCode"] === "OK") {
          updateLoading(false);
          courseCreatedExcel();
        } else {
          updateLoading(false);

          updateErrors(res["result"] ?? ["Something went wrong!"]);
        }
      } else {
        alert("Courses are not created!");
      }

      updateLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const submitBtn = () => {
    if (hotTableComponent.current) {
      const hotInstance = hotTableComponent.current.hotInstance;
      const data = hotInstance.getData();
      // console.log(data);

      // Create a new workbook and a worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate Excel file
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

      const blob = new Blob([wbout], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      //   const url = URL.createObjectURL(blob);
      //   const a = document.createElement("a");
      //   a.href = url;
      //   a.download = "HandsontableData.xlsx";

      //   a.click();

      const formData = new FormData();
      formData.append("file", blob);

      addTemplateManually(formData);
    }
  };

  const goBack = () => {
    excelEntryBtn();
  };

  const excelConvert = (blob) => {
    try {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // Parse the sheet to preserve empty rows
        const range = XLSX.utils.decode_range(sheet["!ref"]);
        const rows = [];
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const row = [];
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            row.push(sheet[cellRef] ? sheet[cellRef].v : null);
          }
          rows.push(row);
        }

        updateExcelData(rows);
      };

      reader.readAsBinaryString(blob);
    } catch (err) {
      console.log(err);
    }
  };

  const fileUpload = (event) => {
    updatePopup(false);
    const file = event.target.files[0];
    const blob = new Blob([file], { type: file.type });
    excelConvert(blob);
  };

  const closeModal = () => {
    updatePopup(false);
  };

  const popUpBtn = () => {
    updatePopup(true);
  };

  const renderPopup = () => (
    <div className={`${styles["modal"]}`}>
      <label className={styles["button-pop"]} htmlFor="upload">
        Choose File
      </label>
      <input
        id="upload"
        onChange={fileUpload}
        type="file"
        accept=".csv,.xlsx,.xls"
        className={`${styles["input-file"]} w-4/12 mr-20`}
      />

      <button
        onClick={downloadTemplateDevice}
        className={`${styles["button-download"]} text-center pb-2`}
      >
        <span className="mr-2">
          <img src="/assets/download.svg" alt="download" />
        </span>
        Download Template
      </button>
    </div>
  );

  const errCls = () => {
    updateErrors([]);
  };

  const downloadTemplateDevice = async () => {
    try {
      const token = Cookies.get("jwt_token");

      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.EXCEl_FILES + `?sampleExcelName=course`,
        options
      );

      if (response["ok"] === true) {
        // Convert the received data into a Blob

        const blobData = await response.blob();

        // Create a Blob object

        const blob = new Blob([blobData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a download link
        const link = document.createElement("a");

        // Set the link's href attribute to the Blob data
        link.href = window.URL.createObjectURL(blob);

        // Set the download attribute with the desired file name
        link.download = "Course Template";

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger a click event on the link to start the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
        alert("Template Downloaded Successfully");
      } else {
        alert("Unable To Download");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      const cells = errors.map((each) => {
        const stringWithArray = each;

        const startIndex = stringWithArray.indexOf("[");
        const endIndex = stringWithArray.lastIndexOf("]");
        const arraySubstring = stringWithArray.substring(
          startIndex,
          endIndex + 1
        );

        return JSON.parse(arraySubstring);
      });

      highlightCells(cells);
    } catch (err) {
      console.log(err);
    }
  }, [errors]);

  return (
    <div className={styles["excel-container"]}>
      <div className={styles["excel-width"]}>
        <HotTable
          ref={hotTableComponent}
          data={excelData}
          cell={preCol}
          cells={(row, col) => {
            const cellProperties = {};

            if (row === 0) {
              cellProperties.readOnly = true;
              cellProperties.className = `${styles["read-only-cell"]}`;
            }

            if (row === 0 && col !== 7) {
              cellProperties.className = `${styles["read-only-cell-custom"]}`;
            }

            if (row > 0 && col === 3) {
              cellProperties.type = "numeric";
            }

            return cellProperties;
          }}
          minSpareRows={40}
          minCols={10}
          maxCols={10}
          colWidths={130}
          width="100%"
          height="100%"
          className={`${styles["custom-handsontable"]}`}
          licenseKey="non-commercial-and-evaluation"
        />

        {errors.length > 0 && (
          <div className={styles["errors-card"]}>
            <div className={styles["err-count-title"]}>
              (Found {errors.length} Errors)
              <button
                type="button"
                className="absolute right-2"
                onClick={errCls}
              >
                <RxCross2 size={22} className="cursor-pointer text-[#456bf1]" />
              </button>
            </div>
            <div className={styles["err-container"]}>
              <ol className={styles["errors-list"]}>{renderErrors()}</ol>
            </div>
          </div>
        )}
      </div>

      <span
        className={`${styles["excel-btns"]} flex flex-row justify-end items-end mt-4 mb-4`}
      >
        <button onClick={goBack} className={styles["back-button"]}>
          Go Back
        </button>

        <button
          onClick={popUpBtn}
          className={`${styles["back-button"]}`}
          type="button"
        >
          <MdOutlineUploadFile className={styles["excel-upload-icon"]} />
          Upload Excel
        </button>

        <Popup open={popUp} onClose={closeModal} {...{ overlayStyle }}>
          {renderPopup()}
        </Popup>
        <button
          disabled={loading}
          onClick={submitBtn}
          className={`${styles["button"]} ${loading && "cursor-not-allowed"}`}
        >
          {loading ? (
            <Oval
              height={20}
              width={85}
              color="#fff"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#fff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "Submit"
          )}
        </button>
      </span>
    </div>
  );
};

export default ExcelView;
