import styles from "./index.module.css";
import { Link } from "react-router-dom";

const ListViewCourses = (props) => {
  const { data, isFacultyLogin, isDownloading } = props;

  const attLink = !isFacultyLogin
    ? `/obe/courses/attainments/${data["course_id"]}`
    : `/faculty/dashboard/attainments/${data["course_id"]}`;

  const renderCoAtt = () => {
    let result = [];
    for (let i = 1; i <= 6; i++) {
      result.push(
        <td
          key={i}
          className={`text-${
            data?.["cos"]?.[`CO${i}`] >= 50 ? "[#27AE7A]" : "[#E35050]"
          }`}
        >
          {data?.["cos"]?.[`CO${i}`] ?? "-"}
          {data?.["cos"]?.[`CO${i}`] && "%"}
        </td>
      );
    }

    return result;
  };

  const renderStructure = () => {
    return (
      <tr>
        <td>{data.course_code}</td>
        <td>{data.course_short_name}</td>

        {renderCoAtt()}

        <td className={`text-${data.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"}`}>
          {data.attained_cos}/{data.total_cos}
        </td>
        <td className={`text-${data.avg_att < 50 ? "[#E35050]" : "[#27AE7A]"}`}>
          {data.avg_att}%
        </td>

        {!isDownloading && (
          <td>
            <Link to={attLink}>
              <button
                className={`${styles["arrow-buttons-table"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </Link>
          </td>
        )}
      </tr>
    );
  };

  return renderStructure();
};

export default ListViewCourses;
