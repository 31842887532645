import { Link, withRouter } from "react-router-dom";
import { MdOutlineSettings } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FiPlusCircle } from "react-icons/fi";
import CoursesList from "./CoursesList";
import ListViewCourses from "./ListViewCourses";
import styles from "./index.module.css";
import CreateCourse from "./CreateCourse";
import CourseStructure from "./CourseStructure";
import ModifyWeightages from "./ModifyWeightages";
import NewCourseStructure from "./NewCourseStructure";
import { useState, useEffect, useContext } from "react";
import apiClient from "../../../../utils/apiUrls/apiClient";
import Cookies from "js-cookie";
import { Oval } from "react-loader-spinner";
import * as jose from "jose";
import CourseOutcome from "../../../FacultyModule/CourseOutcome";
import QuestionPaperMapping from "../../../FacultyModule/QuestionPaperMapping";
import FeedBack from "../../../FacultyModule/FeedBack";
import SearchContext from "../../../../context/SearchContext";
import roles from "../../../roles";
import FiltersContext from "../../../../context/FiltersContext";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { flushSync } from "react-dom";

const ManageCourses = (props) => {
  const { filtersBatch, filtersBranch, filtersSem } =
    useContext(FiltersContext);

  const [isOpened, updateOpened] = useState(false);
  const [loader, updateLoader] = useState(true);
  const [listGridBtn, updateListGridBtn] = useState(true);

  const [active, updateActive] = useState(null);
  const [inputDataActive, updateInputDataActive] = useState(null);
  const [dataId, updateDataId] = useState(null);

  const { search } = useContext(SearchContext);

  const [filters, updateFilters] = useState({
    batch: "",
    branch: "",
    semester: "",
    section: "",
  });

  const [data, updateData] = useState([]);
  const [dataCross, updateDataCross] = useState([]);
  const [content, updateContent] = useState("loading");
  const [dropBatchOptions] = useState(filtersBatch);
  const [dropBranchOptions] = useState(filtersBranch);
  const [dropSemOptions] = useState(filtersSem);
  const [dropSectionOptions, UpdateSectionOptions] = useState([]);
  const [userType, updateType] = useState("");
  const [item, updateItem] = useState(null);
  const [courseData, updateCourseData] = useState(null);
  const [course, setCourses] = useState([]);
  const [abortControllerCourses, setAbortControllerCourses] = useState(null);

  const [pagesCount, updatePagesCount] = useState(1);
  const [page, updatePage] = useState(1);

  const [isDownloading, setIsDownloading] = useState(false);

  const getCrossCourses = async () => {
    const token = Cookies.get("jwt_token");
    try {
      if (userType === "HOD") {
        const responseCross = await apiClient.get(
          apiClient.urls.fatcat.OBE_MANAGE_COURSES_CROSS_BRANCH +
            `?batch=${filters.batch}&branch=${filters.branch}&regulation=&semester=${filters.semester}&section=${filters.section}`,
          { Authorization: token }
        );

        if (responseCross["data"]["statusCode"] === "OK") {
          updateContent(true);
          updateDataCross(
            responseCross["data"]["result"]["courseDetailsResponses"]
          );
        } else {
          updateDataCross([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCourses = async () => {
    updateLoader(true);
    const token = Cookies.get("jwt_token");

    try {
      const response = await apiClient.get(
        apiClient.urls.fatcat.OBE_FACULTY_MANAGE_COURSES_LIST +
          `?batch=${filters.batch}&branch=${
            filters.branch
          }&regulation=&semester=${
            userType === "FRESHMAN" && filters.semester === ""
              ? "1"
              : filters.semester
          }&section=${filters.section}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        updateContent(true);
        updateData(response["data"]["result"]["courseDetailsResponses"]);
      } else {
        updateContent(false);
        updateData([]);
      }

      await getCrossCourses();
    } catch (err) {
      console.log(err);
    }

    updateLoader(false);
  };

  const getCoCourses = async () => {
    if (abortControllerCourses) {
      abortControllerCourses.abort();
    }

    const controller = new AbortController();
    setAbortControllerCourses(controller);

    const { signal } = controller;

    const token = Cookies.get("jwt_token");
    updateLoader(true);
    setCourses([]);

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    let queryParams = `?batchId=${filters.batch}&branch=${filters.branch}&sortBy=&semester=${filters.semester}`;

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_STUDENTS_STUDENTS_COURSES_CO_ATTAINMENTS +
          queryParams,
        { ...options, signal }
      );

      if (response.ok) {
        const data = await response.json();
        updateContent(true);
        setCourses(data);
      }
    } catch (err) {
      console.log(err);
    }
    updateLoader(false);
  };

  useEffect(() => {
    if (navigator.onLine) {
      const token = Cookies.get("jwt_token");
      if (token !== undefined) {
        const claim = jose.decodeJwt(token);
        updateType(claim["user_type"]);

        if (claim["user_type"] === roles.hod) {
          updateFilters({ ...filters, branch: claim["dept_name"] });
        } else if (claim["user_type"] === roles.freshMan) {
          updateFilters({
            ...filters,
            sem: filtersSem?.[0]?.["optionValue"] ?? "",
          });
        } else {
          updateFilters({ ...filters, branch: "" });
        }
      }
    }
  }, []);

  useEffect(() => {
    listGridBtn ? getCourses() : getCoCourses();
  }, [listGridBtn]);

  useEffect(() => {
    if (navigator.onLine) {
      if (Cookies.get("jwt_token") !== undefined) {
        if (userType !== "") {
          listGridBtn ? getCourses() : getCoCourses();
        }
      }
    }
  }, [filters]);

  const getSectionOptions = async () => {
    const token = Cookies.get("jwt_token");
    try {
      let batch = dropBatchOptions.filter(
        (each) => `${each.optionValue}` === `${filters.batch}`
      );

      const response = await apiClient.get(
        apiClient.urls.fatcat.GET_SECTIONS +
          `?branch=${filters.branch}&batch=${batch?.[0]?.["optionDesc"] ?? ""}`,
        { Authorization: token }
      );

      if (response["data"]["statusCode"] === "OK") {
        UpdateSectionOptions(response["data"]["result"]);
      } else {
        UpdateSectionOptions([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSectionOptions();
  }, [filters.batch, filters.branch]);

  useEffect(() => {
    const len = course.length;
    const pages = Math.ceil(len / 30);
    updatePagesCount(pages === 0 ? 1 : pages);
    updatePage(1);
  }, [course]);

  const branchChanged = (event) => {
    updateFilters({ ...filters, branch: event.target.value, section: "" });
  };

  const batchChanged = (event) => {
    const val = event.target.value;
    updateFilters({
      ...filters,
      batch: `${filters.batch}` === `${val}` ? "" : val,
      section: "",
    });
  };

  const semesterChanged = (event) => {
    const val = event.target.value;

    updateFilters({
      ...filters,
      semester: `${filters.semester}` === `${val}` ? "" : val,
    });
  };

  const sectionChanged = (event) => {
    const val = event.target.id;

    updateFilters({
      ...filters,
      section: `${filters.section}` === `${val}` ? "" : val,
    });
  };

  const { match } = props;
  const { path } = match;

  const closeBtn = () => {
    updateOpened(false);
    updateActive(null);
    updateInputDataActive(null);
    updateDataId(null);
    updateItem(null);
    updateCourseData(null);
  };

  const openBtn = () => {
    updateOpened(true);
    createCourseBtn();
  };

  const openInputBtn = (id) => {
    updateOpened(true);
    updateDataId(id);
  };

  const itemUpdate = (item) => {
    updateItem(item);
    newCourseBtn();
  };

  const deleteCourse = async (id) => {
    const token = Cookies.get("jwt_token");
    try {
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_DELETE_COURSE + `?courseId=${id}`,
        options
      );

      if (response.ok === true) {
        const res = await response.json();

        if (res["statusCode"] === "OK") {
          await getCourses();
          await getCrossCourses();
          alert("Course deleted successfully");
        } else {
          alert("Oops! Course not deleted");
        }
      } else {
        alert("Oops! Course not deleted");
      }
      await getCrossCourses();
    } catch (err) {
      console.log(err);
    }
  };

  const updateCourse = async (item) => {
    try {
      updateActive(1);
      updateOpened(true);
      updateCourseData(item);
    } catch (err) {
      console.log(err);
    }
  };

  const renderView = () => {
    try {
      if (inputDataActive !== null) {
        const coursesCombined = [...data, ...dataCross];
        const ans = coursesCombined.filter(
          (each) => `${each.courseId}` === `${dataId}`
        );

        switch (inputDataActive) {
          case 1:
            return (
              <CourseOutcome item={ans} cls={closeBtn} reload={getCourses} />
            );
          case 2:
            return (
              <QuestionPaperMapping
                cls={closeBtn}
                item={ans}
                reload={getCourses}
              />
            );

          case 3:
            return <FeedBack item={ans} cls={closeBtn} reload={getCourses} />;

          default:
            break;
        }
      } else {
        switch (active) {
          case 1:
            return (
              <CreateCourse
                item={courseData}
                reload={getCourses}
                newCourseBtn={newCourseBtn}
                closeBtn={closeBtn}
              />
            );
          case 2:
            return <CourseStructure itemUpdate={itemUpdate} />;
          case 3:
            return <NewCourseStructure itemUpdate={itemUpdate} edit={item} />;
          case 4:
            return <ModifyWeightages />;
          default:
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const preventBubbling = (event) => {
    event.stopPropagation();
  };

  const createCourseBtn = () => {
    updateActive(1);
    updateItem(null);
  };

  const courseStrucBtn = () => {
    updateActive(2);
    updateItem(null);
  };

  const newCourseBtn = () => {
    updateActive(3);
  };

  const modifyBtn = () => {
    const val = active === 4 ? 1 : 4;

    updateActive(val);
  };

  const renderEmptyCourses = () => (
    <div className="relative flex flex-col top-1/4 items-center min-h-screen">
      <img
        src="/assets/cos.svg"
        width={361}
        height={38}
        alt="COURSE OUTCOMES"
      />
      <p className={styles["no-courses-text"]}>
        You Don&apos;t Have Any Courses Created
      </p>
      <button onClick={openBtn} className={styles["cc-btn"]}>
        Create Courses
      </button>
    </div>
  );

  const renderBatchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBatchOptions) {
        ans.push(
          <li
            className={
              `${filters.batch}` === `${item["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item["optionValue"]}
            value={item["optionValue"]}
            onClick={batchChanged}
          >
            {item["optionDesc"]}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderBranchOptions = () => {
    const ans = [];
    try {
      for (const item of dropBranchOptions) {
        ans.push(
          <option key={item["optionValue"]} value={item["optionValue"]}>
            {item["optionDesc"]}
          </option>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSemOptions = () => {
    const ans = [];
    try {
      if (userType["user_type"] === "FRESHMAN") {
        ans.push(
          <li
            className={
              `${filters.semester}` === `${dropSemOptions[0]["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={dropSemOptions[0]["optionValue"]}
            value={dropSemOptions[0]["optionValue"]}
            onClick={semesterChanged}
          >
            {dropSemOptions[0]["optionDesc"]}
          </li>
        );

        ans.push(
          <li
            className={
              `${filters.semester}` === `${dropSemOptions[1]["optionValue"]}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={dropSemOptions[1]["optionValue"]}
            value={dropSemOptions[1]["optionValue"]}
            onClick={semesterChanged}
          >
            {dropSemOptions[1]["optionDesc"]}
          </li>
        );
      } else {
        for (const item of dropSemOptions) {
          ans.push(
            <li
              className={
                `${filters.semester}` === `${item["optionValue"]}`
                  ? styles["batches-ul-active"]
                  : "bg-[#fff] text-[#606981]"
              }
              key={item["optionValue"]}
              value={item["optionValue"]}
              onClick={semesterChanged}
            >
              {item["optionDesc"]}
            </li>
          );
        }
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const renderSectionOptions = () => {
    const ans = [];
    try {
      for (const item of dropSectionOptions) {
        ans.push(
          <li
            className={
              `${filters.section}` === `${item}`
                ? styles["batches-ul-active"]
                : "bg-[#fff] text-[#606981]"
            }
            key={item}
            id={item}
            onClick={sectionChanged}
          >
            {item}
          </li>
        );
      }
      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  const coBtn = async (id) => {
    updateInputDataActive(1);
    openInputBtn(id);
  };

  const quesPaperBtn = async (id) => {
    updateInputDataActive(2);
    openInputBtn(id);
  };

  const feedbackBtn = async (id) => {
    updateInputDataActive(3);
    openInputBtn(id);
  };

  const renderViewList = () => {
    updateListGridBtn(!listGridBtn);
  };

  const pageChange = (event) => {
    if (event.target.name === "plus") {
      page < pagesCount && updatePage(page + 1);
    } else {
      page > 1 && updatePage(page - 1);
    }
  };

  const downPdf = async () => {
    setIsDownloading(true);
    updatePage(1);

    const pdf = new jsPDF("p", "mm", "a4");

    try {
      for (let i = 1; i <= pagesCount; i++) {
        flushSync(() => {
          updatePage(i); // Force synchronous state update
        });

        const pageContent = document.getElementById("courseAttListPdf");
        const canvas = await html2canvas(pageContent);
        const clgDataImg = canvas.toDataURL("image/png");

        const pdfWidth = pdf.internal.pageSize.getWidth();
        // const pdfHeight = pdf.internal.pageSize.getHeight();

        // Adjust the size and position of the image on the PDF page
        const imgWidth = 180; // A4 paper width
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        const xPos = (pdfWidth - imgWidth) / 1.8;
        // const yPos = (pdfHeight - imgHeight) / 2;

        pdf.addImage(clgDataImg, "PNG", xPos, 10, imgWidth, imgHeight);

        i < pagesCount && pdf.addPage();
      }

      pdf.save("Report.pdf");
    } catch (error) {
      console.error("Error converting HTML to PDF:", error);
    }

    setIsDownloading(false);
  };

  const renderInitialView = () => {
    try {
      switch (content) {
        case true:
          const ans = [...data, ...dataCross];

          let filteredSearch = [];

          if (listGridBtn) {
            filteredSearch = ans.filter(
              (item) =>
                item["courseName"] !== null &&
                item["courseShortName"] !== null &&
                (item["courseName"]
                  .toLowerCase()
                  .includes(search.toLowerCase()) ||
                  item["courseShortName"]
                    .toLowerCase()
                    .includes(search.toLowerCase()))
            );
          } else {
            filteredSearch = course;
          }

          return listGridBtn ? (
            filteredSearch.map((each) => (
              <CoursesList
                deleteCourse={deleteCourse}
                updateCourse={updateCourse}
                item={each}
                key={each.courseId}
                coBtn={coBtn}
                quesPaperBtn={quesPaperBtn}
                feedbackBtn={feedbackBtn}
              />
            ))
          ) : (
            <div className={`${styles["bg-containers"]} w-full`}>
              <span className="flex justify-end items-center mb-4">
                {filteredSearch.length > 0 && !isDownloading && (
                  <button
                    onClick={downPdf}
                    className={`${styles["table-btns"]} flex items-center mr-8`}
                    type="button"
                  >
                    <img
                      src="/assets/download.svg"
                      alt="add"
                      className="mr-2"
                    />
                    Download
                  </button>
                )}

                <div className="w-2/12 flex justify-between items-center">
                  <button
                    name="minus"
                    onClick={pageChange}
                    className={styles["arrow-buttons-table"]}
                  >
                    <img
                      name="minus"
                      src="/assets/left-arr-outline.svg"
                      alt="arrow"
                      width={8}
                      height={8}
                    />
                  </button>
                  <p className={styles["table-list-count"]}>
                    {page} of {pagesCount}
                  </p>
                  <button
                    name="plus"
                    onClick={pageChange}
                    className={`${styles["arrow-buttons-table"]} ${styles["right-btn"]}`}
                  >
                    <img
                      name="plus"
                      src="/assets/left-arr-outline.svg"
                      alt="arrow"
                      width={8}
                      height={8}
                    />
                  </button>
                </div>
              </span>
              <table
                id="courseAttListPdf"
                className={styles["courses-table-container"]}
              >
                <thead>
                  <tr>
                    <th>Course Code</th>
                    <th>Course Name</th>
                    <th>CO1</th>
                    <th>CO2</th>
                    <th>CO3</th>
                    <th>CO4</th>
                    <th>CO5</th>
                    <th>CO6</th>
                    <th>Outcomes</th>
                    <th>Avg Attainment</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredSearch
                    .slice((page - 1) * 30, page * 30)
                    .map((each) => (
                      <ListViewCourses
                        key={each.course_id}
                        data={each}
                        isDownloading={isDownloading}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          );

        case false:
          return renderEmptyCourses();
        case "loading":
          return (
            <div className="fixed top-2/4 left-2/4">
              <Oval
                height={80}
                width={80}
                color="#3D65F4"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#3D65F4"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          );
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const batchTabLeft = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "left", 25, 250, 20);
  };

  const batchTab = () => {
    const container = document.getElementById("batch-tabs");
    sideScroll(container, "right", 25, 250, 20);
  };

  const semTabLeft = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "left", 25, 150, 20);
  };

  const semTab = () => {
    const container = document.getElementById("sem-tabs");
    sideScroll(container, "right", 25, 150, 20);
  };

  const secTabLeft = () => {
    const container = document.getElementById("sec-tabs");
    sideScroll(container, "left", 25, 80, 20);
  };

  const secTab = () => {
    const container = document.getElementById("sec-tabs");
    sideScroll(container, "right", 25, 80, 20);
  };

  const sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <>
      <div onClick={closeBtn} className={isOpened ? styles.overlay : "hidden"}>
        <div
          onClick={preventBubbling}
          className={
            inputDataActive === null
              ? styles["overlay-card"]
              : styles["overlay-card-input-data"]
          }
        >
          {inputDataActive === null && (
            <>
              <span className="flex flex-row justify-between">
                <h1 className={styles["overlay-title"]}>
                  {active === 4
                    ? "Weightages & PO IDA"
                    : "Enter Course Details"}
                </h1>
                <span className="flex items-center">
                  {courseData === null && (
                    <button
                      onClick={modifyBtn}
                      className={
                        isOpened
                          ? "flex flex-row justify-center items-center text-[#456BF1] rounded-3xl w-9 h-9 p-1 mr-5"
                          : "hidden"
                      }
                    >
                      <MdOutlineSettings
                        className={`${active === 4 && "transform rotate-90"}`}
                        size={26}
                      />
                    </button>
                  )}
                  <RxCross2
                    onClick={closeBtn}
                    size={26}
                    className="cursor-pointer text-[#456bf1]"
                  />
                </span>
              </span>
              {active !== 4 && (
                <div className="bg-[#FFFFFF] p-5 mt-4">
                  <span className="flex flex-row">
                    <button
                      onClick={createCourseBtn}
                      type="button"
                      className={`hover:text-[#3f4451] ${
                        active === 1
                          ? styles["overlay-tab-active"]
                          : styles["overlay-tab"]
                      } mb-5 mr-12`}
                    >
                      Create Course
                    </button>

                    {courseData === null && (
                      <>
                        <button
                          onClick={newCourseBtn}
                          type="button"
                          className={`hover:text-[#3f4451] ${
                            active === 3
                              ? styles["overlay-tab-active"]
                              : styles["overlay-tab"]
                          } mb-5 mr-12`}
                        >
                          Create New Course Structure
                        </button>
                        <button
                          onClick={courseStrucBtn}
                          type="button"
                          className={`hover:text-[#3f4451] ${
                            active === 2
                              ? styles["overlay-tab-active"]
                              : styles["overlay-tab"]
                          } mb-5`}
                        >
                          Saved Structures
                        </button>
                      </>
                    )}
                  </span>
                </div>
              )}
            </>
          )}

          {renderView()}
        </div>
      </div>

      <div className="ml-7 mt-2">
        <div className="flex flex-row items-center mt-20">
          <Link to="/obe/courses/managecourses">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/courses/managecourses"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-4 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
            >
              MANAGE COURSES
            </h1>
          </Link>

          <Link to="/obe/courses/attainments">
            <h1
              className={`hover:font-bold hover:[#464D90] ${
                path === "/obe/courses/analysis"
                  ? "font-bold underline"
                  : "font-medium"
              } ml-6 mb-1 text-[#040C58] underline-offset-8 decoration-2 cursor-pointer`}
            >
              ATTAINMENTS
            </h1>
          </Link>
        </div>
      </div>

      <div className={`${styles["students-bg"]} mb-2 pl-5`}>
        {!(userType === roles.hod) && (
          <div className={`${styles["students-filters"]} w-32 ml-2`}>
            <select
              value={filters.branch}
              className={`${styles["filters"]} focus:ring-0 text-blue-600 border-none mt-7 mb-1.5 pr-5`}
              onChange={branchChanged}
            >
              <option value="">Branch</option>
              {renderBranchOptions()}
            </select>
          </div>
        )}

        <div className={`${styles["students-filters"]} ml-1 w-5/12`}>
          <h1 className={`${styles["filters-title"]}`}>Choose Batch</h1>
          <div className="flex relative">
            <button onClick={batchTabLeft} className={styles["arrow-buttons"]}>
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
            <ul id="batch-tabs" className={styles["batches-ul"]}>
              {renderBatchOptions()}
            </ul>

            <button
              onClick={batchTab}
              className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
            >
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
          </div>
        </div>

        <div className={`${styles["students-filters"]} ml-4 w-3/12`}>
          <h1 className={`${styles["filters-title"]}`}>Semester</h1>

          <div className="flex relative">
            <button onClick={semTabLeft} className={styles["arrow-buttons"]}>
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
            <ul
              id="sem-tabs"
              className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
            >
              {renderSemOptions()}
            </ul>

            <button
              onClick={semTab}
              className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
            >
              <img
                src="/assets/left-arr-outline.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </button>
          </div>
        </div>

        {dropSectionOptions.length > 0 && (
          <div className={`${styles["students-filters"]} ml-4 w-2/12`}>
            <h1 className={`${styles["filters-title"]}`}>Section</h1>

            <div className="flex relative">
              <button onClick={secTabLeft} className={styles["arrow-buttons"]}>
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
              <ul
                id="sec-tabs"
                className={`${styles["sem-ul"]} ${styles["sem-filter"]}`}
              >
                {renderSectionOptions()}
              </ul>

              <button
                onClick={secTab}
                className={`${styles["arrow-buttons"]} ${styles["right-btn"]}`}
              >
                <img
                  src="/assets/left-arr-outline.svg"
                  alt="arrow"
                  width={8}
                  height={8}
                />
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="ml-7">
        <div className="flex flex-row justify-between items-center mr-10">
          <h1 className={styles["total-courses"]}>
            TOTAL COURSES : {content ? data.length + dataCross.length : 0}
          </h1>

          <div className="flex flex-row gap-5">
            <span
              className={`${styles["list-grid-btns"]} pointer hover:bg-[#456bf1] hover:text-[#F4F9FF]`}
              onClick={renderViewList}
            >
              <button type="button" className="flex items-center p-0">
                {listGridBtn ? "List View" : "Grid View"}
              </button>
            </span>

            <span
              className={`${styles["info-btns"]} hover:bg-[#456bf1] hover:text-[#F4F9FF]`}
              onClick={openBtn}
            >
              <button type="button" className="flex items-center">
                <span className="mr-2">
                  <FiPlusCircle
                    size={18}
                    className="hover:bg-[#456bf1] hover:text-[#F4F9FF]"
                  />
                </span>
                Create New Course
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className={styles["dashboard-periodwisebar-bg"]}>
        {loader ? (
          <div className="flex justify-center mt-10 pb-5">
            <Oval
              height={50}
              width={50}
              color="#3d65f4"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#3d65f4"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <ul
            className={`${
              !content && "justify-center"
            } list-none w-full flex flex-row flex-wrap ml-1`}
          >
            {renderInitialView()}
          </ul>
        )}
      </div>
    </>
  );
};

export default withRouter(ManageCourses);
