import { useEffect, useState } from "react";
import apiClient from "../../../../utils/apiUrls/apiClient";
import { Oval } from "react-loader-spinner";
import Cookies from "js-cookie";
import styles from "../index.module.css";

const QuestionPaperScreen = (props) => {
  const [loading, updateLoading] = useState(false);
  const [status, updateStatus] = useState([]);
  const [avail, updateAvail] = useState([]);

  const { data, listName, updateQpTemplateName } = props;

  const token = Cookies.get("jwt_token");

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const fetchAnalysis = async (name) => {
    const options = {
      method: "GET",
      headers,
    };

    try {
      const res = await fetch(
        apiClient.urls.fatcat.OBE_QUESTION_PAPER_ANALYSIS +
          `?courseId=${data?.courseId}&templateName=${name}`,
        options
      );

      if (res.ok) {
        const data = await res.json();
        if (Object.keys(data).length === 0) {
          updateAvail((prev) => [...new Set([...prev, name])]);
        } else {
          const a = avail.filter((i) => i !== name);
          updateAvail(a);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const addQuestionPaper = async (event) => {
    updateLoading(true);
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);

    const token = Cookies.get("jwt_token");

    const options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(
        apiClient.urls.fatcat.OBE_EXAM_PAPER_UPLOAD +
          `?courseId=${data?.courseId}&templateName=${event?.target?.id}`,
        options
      );

      if (response.ok) {
        const res = await response.json();

        if (res["statusCode"] === "OK" || res["statusCode"] === "ACCEPTED") {
          alert("Question paper uploaded successfully!");
        } else {
          alert("Question paper not uploaded");
        }
      } else {
        alert("Question paper not uploaded");
      }
    } catch (err) {
      alert("Question paper not uploaded");
      console.log(err);
    }

    getStatus();
    updateLoading(false);
    event.target.value = "";
  };

  const viewMappingBtn = (event) => {
    updateQpTemplateName(event.target.name, "mappedQuestionPaper", status);
  };

  const viewAnalysisBtn = (event) => {
    updateQpTemplateName(event.target.name, "questionPaperAnalysis", status);
  };

  const viewTotalAnalysisBtn = () => {
    updateQpTemplateName("", "questionPaperAnalysis", status);
  };

  const renderLiView = () => {
    try {
      return listName.map((each) => {
        return (
          <li className={styles["li-item"]} key={each}>
            <span className="flex items-start">
              <span className="mr-20">
                <label className={styles.label}>Assessment Type</label>
                <p className={styles["temp-name"]}>{each}</p>
              </span>
            </span>

            <div className="flex items-center">
              {!status.includes(each) && (
                <span className="mt-2 mr-1 flex flex-col justify-start items-start">
                  <label className={styles.label}>Upload File</label>

                  <div className={styles["container"]}>
                    <div className={styles["button-wrap"]}>
                      <label className={styles["button"]} htmlFor={each}>
                        {loading ? (
                          <Oval
                            height={20}
                            width={85}
                            color="#fff"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#fff"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                          />
                        ) : status.includes(each) ? (
                          "RE-UPLOAD"
                        ) : (
                          "CHOOSE FILE"
                        )}
                      </label>
                      <input
                        disabled={avail.includes(each)}
                        id={each}
                        onChange={addQuestionPaper}
                        type="file"
                        accept=".pdf"
                        className={`${styles["input-file"]} w-4/12 mr-10`}
                      />

                      <p className={`${styles["file"]} flex items-center`}>
                        {status.includes(each) && (
                          <span className="ml-1 flex items-center">
                            <img
                              className="mr-2"
                              src="/assets/green-tick.svg"
                              alt="Uploaded"
                              width={22}
                              height={22}
                            />
                            File Uploaded
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                </span>
              )}

              <p className={`${styles["file"]} flex items-center`}>
                {status.includes(each) && (
                  <span className="ml-1 flex items-center">
                    <img
                      className="mr-2"
                      src="/assets/green-tick.svg"
                      alt="Uploaded"
                      width={22}
                      height={22}
                    />
                    File Uploaded
                  </span>
                )}
              </p>

              <span className="flex items-center self-end ml-auto">
                <button
                  disabled={avail.includes(each)}
                  name={each}
                  onClick={viewMappingBtn}
                  className={`${
                    styles.buttons
                  } ml-auto flex flex-row items-center self-end bg-[#F4F9FF] border rounded-2xl px-8 py-1.5 ${
                    avail.includes(each) && "cursor-not-allowed"
                  }
                `}
                  type="button"
                >
                  {avail.includes(each) ? (
                    <Oval
                      height={20}
                      width={85}
                      color="#456bf1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#456bf1"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    "View Mapping"
                  )}
                </button>

                <button
                  disabled={avail.includes(each)}
                  name={each}
                  onClick={viewAnalysisBtn}
                  className={`${
                    styles.buttons
                  } ml-5 flex flex-row items-center self-end bg-[#F4F9FF] border rounded-2xl px-8 py-1.5 ${
                    avail.includes(each) && "cursor-not-allowed"
                  }`}
                  type="button"
                >
                  {avail.includes(each) ? (
                    <Oval
                      height={20}
                      width={85}
                      color="#456bf1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#456bf1"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (
                    "View Analysis"
                  )}
                </button>
              </span>
            </div>
          </li>
        );
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getStatus = async () => {
    try {
      const token = Cookies.get("jwt_token");

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(
        apiClient.urls.fatcat.OBE_QUESTION_PAPER_UPLOAD_STATUS +
          `?courseId=${data?.["courseId"]}`,
        options
      );

      if (response.ok) {
        const data = await response.json();
        updateStatus(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    data?.courseId !== undefined && getStatus();
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      getStatus();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    status.forEach((each) => {
      fetchAnalysis(each);
    });
  }, [status]);

  return (
    <>
      <div className="flex flex-col justify-start flex-wrap text-[#636363] bg-[#FFFFFF] shadow p-5 pb-10 h-4/5 overflow-y-auto mt-1">
        <ul className="list-none">{renderLiView()}</ul>
      </div>

      <span className="flex flex-row justify-end items-end mt-5 mb-0 fixed right-3 bottom-1">
        <span className="flex flex-row justify-end items-end mt-5 mb-5">
          <div className={styles["container"]}>
            <button
              disabled={avail.length !== 0}
              onClick={viewTotalAnalysisBtn}
              className={`${styles["analysis-btn"]} ${
                avail.length !== 0 && "cursor-not-allowed"
              }`}
            >
              {avail.length !== 0 ? (
                <Oval
                  height={20}
                  width={85}
                  color="#fff"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#fff"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              ) : (
                "See Total Analysis"
              )}
            </button>
          </div>
        </span>
      </span>
    </>
  );
};

export default QuestionPaperScreen;
