import styles from "./index.module.css";
import Cookies from "js-cookie";
import apiClient from "../../../../../utils/apiUrls/apiClient";
import { Component } from "react";
import { Oval } from "react-loader-spinner";

const dummyPsoData = [
  { poPsoName: "PO1", poAttvalue: "" },
  { poPsoName: "PO2", poAttvalue: "" },
  { poPsoName: "PO3", poAttvalue: "" },
  { poPsoName: "PO4", poAttvalue: "" },
  { poPsoName: "PO5", poAttvalue: "" },
  { poPsoName: "PO6", poAttvalue: "" },
  { poPsoName: "PO7", poAttvalue: "" },
  { poPsoName: "PO8", poAttvalue: "" },
  { poPsoName: "PO9", poAttvalue: "" },
  { poPsoName: "PO10", poAttvalue: "" },
  { poPsoName: "PO11", poAttvalue: "" },
  { poPsoName: "PO12", poAttvalue: "" },
];

class PoAttainmentTable extends Component {
  state = {
    loader: false,
    data: null,
    psoData: dummyPsoData,
    coAttData: null,
  };

  getCoAttData = async () => {
    try {
      if (this.abortController) {
        this.abortController.abort();
      }

      this.abortController = new AbortController();
      this.setState({ coAttData: null });

      const { courseCode, secOptions } = this.props;
      const joinedSec = secOptions.join();

      const token = Cookies.get("jwt_token");

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.COURSE_ANALYSIS +
            `?courseId=${courseCode}&sectionList=${joinedSec}`,
          { Authorization: token },
          {
            signal: this.abortController.signal,
          }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState({ coAttData: response["data"]["result"] });
        } else {
          this.setState({ coAttData: null });
        }
      } else {
        this.setState({ coAttData: null });
      }
    } catch (err) {
      console.log(err);
    }
  };

  componentDidUpdate(prevProps) {
    const { batch, branch, sem, courseCode } = this.props;

    if (
      prevProps.batch !== batch ||
      prevProps.branch !== branch ||
      prevProps.sem !== sem ||
      prevProps.courseCode !== courseCode
    ) {
      this.getCoAttData();
      this.getData();
      this.getPsoData();
    }
  }

  componentDidMount() {
    this.getCoAttData();
    this.getData();
    this.getPsoData();
  }

  renderDynamicPsosValues = () => {
    const { psoData } = this.state;

    const ans = [];
    try {
      for (let index = 12; index < psoData.length; index++) {
        ans.push(
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[index]?.["poAttvalue"] ?? ""}
          </td>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  renderPsoData = () => {
    const { psoData } = this.state;
    const { activeCourse } = this.props;
    try {
      return (
        <tr className="border border-blue-500 text-[#040C58] font-semibold">
          <th className="border border-blue-500 p-2">
            {activeCourse?.courseShortName}
          </th>

          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[0]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[1]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[2]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[3]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[4]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[5]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[6]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[7]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[8]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[9]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[10]?.["poAttvalue"] ?? ""}
          </td>
          <td
            className={`${styles["table-td"]} border border-blue-500 p-2 text-[#606981]`}
          >
            {psoData?.[11]?.["poAttvalue"] ?? ""}
          </td>
          {this.renderDynamicPsosValues()}
        </tr>
      );
    } catch (err) {
      console.log(err);
    }
  };

  renderDynamicPsos = () => {
    const { psoData } = this.state;
    const ans = [];
    try {
      for (let index = 12; index < psoData.length; index++) {
        ans.push(
          <th className="border border-blue-500 p-2">
            {psoData?.[index]?.["poPsoName"]}
          </th>
        );
      }

      return ans;
    } catch (err) {
      console.log(err);
    }
  };

  poscrollTbClickedLeft = () => {
    const container = document.getElementById("poscrollTb");
    this.sideScroll(container, "left", 25, 100, 400);
  };

  poscrollTbClicked = () => {
    const container = document.getElementById("poscrollTb");
    this.sideScroll(container, "right", 25, 100, 400);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  toggleButtons = (name) => {
    const el = document.getElementById(name);
    if (el !== null) {
      if (el.scrollWidth > el.clientWidth) {
        return true;
      }
      return false;
    }
  };

  getPsoData = async () => {
    this.setState({ psoData: dummyPsoData });
    try {
      const { batch, branch, sem, courseCode } = this.props;

      const token = Cookies.get("jwt_token");

      if (batch !== "" && branch !== "" && sem !== "" && courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.PO_PSO_ATTAINMENT +
            `?batch=${batch}&branch=${branch}&semester=${sem}&courseId=${courseCode}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState({ psoData: response["data"]["result"] });
        } else {
          this.setState({ psoData: dummyPsoData });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  getData = async () => {
    this.setState({ loader: true, data: null });
    try {
      const { courseCode } = this.props;

      const token = Cookies.get("jwt_token");

      if (courseCode !== "") {
        const response = await apiClient.get(
          apiClient.urls.fatcat.OBE_ATTAINMENTS_PO_PSO_ATT_CALCULATIONS +
            `?courseId=${courseCode}`,
          { Authorization: token }
        );

        if (response["data"]["statusCode"] === "OK") {
          this.setState({ data: response["data"]["result"] });
        } else {
          this.setState({ data: null });
        }
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({ loader: false });
  };

  renderLoader = () => (
    <div className="relative flex justify-center mr-32 mt-24 mb-16">
      <Oval
        height={60}
        width={60}
        color="#3D65F4"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#3D65F4"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );

  renderPoPsoScreen = () => (
    <div className={styles["bg-containers"]}>
      <p className={styles["art-matrix-title"]}>
        Direct Program Outcomes and Program-Specific Outcome Attainment
      </p>

      <>
        <div className={styles.table}>
          <div
            id="poscrollTb"
            className={`${styles["table-scroll"]} w-full border border-l-0 border-b-0 border-blue-500 m-0`}
          >
            <table className="text-center border border-blue-500">
              <tbody>
                <tr
                  className={`${styles["table-th"]} border border-blue-500 text-[#040C58] bg-[#EFF3FF]`}
                >
                  <th className="border border-blue-500 p-2">Course Name</th>
                  <th className="border border-blue-500 p-2">PO1</th>
                  <th className="border border-blue-500 p-2">PO2</th>
                  <th className="border border-blue-500 p-2">PO3</th>
                  <th className="border border-blue-500 p-2">PO4</th>
                  <th className="border border-blue-500 p-2">PO5</th>
                  <th className="border border-blue-500 p-2">PO6</th>
                  <th className="border border-blue-500 p-2">PO7</th>
                  <th className="border border-blue-500 p-2">PO8</th>
                  <th className="border border-blue-500 p-2">PO9</th>
                  <th className="border border-blue-500 p-2">PO10</th>
                  <th className="border border-blue-500 p-2">PO11</th>
                  <th className="border border-blue-500 p-2">PO12</th>
                  {this.renderDynamicPsos()}
                </tr>
                {this.renderPsoData()}
              </tbody>
            </table>
          </div>
        </div>
        {this.toggleButtons("poscrollTb") && (
          <span className="flex justify-end pb-5">
            <span
              onClick={this.poscrollTbClickedLeft}
              className={`${styles["floatarr"]} self-end relative bottom-0 top-1 right-4 bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer`}
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
            <span
              onClick={this.poscrollTbClicked}
              className="relative bottom-0 top-1 right-3 self-end bg-[#1C60FF] rounded-3xl pt-1.5 pb-1.5 pl-2 pr-2 cursor-pointer"
            >
              <img
                src="/assets/floatarrow.svg"
                alt="arrow"
                width={8}
                height={8}
              />
            </span>
          </span>
        )}
      </>
    </div>
  );

  renderPoPsoThroughCoScreen = () => {
    const { data, psoData, coAttData } = this.state;
    const { activeCourse } = this.props;
    return (
      <div className={styles["bg-containers"]}>
        <p className={styles["art-matrix-title"]}>
          Attainment Of POs & PSOs Through COs
        </p>
        <table
          className={`${styles["marks-dist-table"]} w-full text-center border border-blue-500 mt-5`}
        >
          <tr className="border border-blue-500 p-0 w-full">
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 w-1/5 "
              rowSpan="2"
            >
              Course Outcomes
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 w-1/5 "
              rowSpan="2"
            >
              CO <br /> Attainment <br /> (A)
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO1
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO2
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO3
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO4
            </th>
          </tr>
          <tr className="border border-blue-500 p-0 w-full">
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
          </tr>

          {Object.keys(data?.PO1 ?? {}).map((each, idx) => (
            <tr className="border border-blue-500 p-0 w-full">
              <td className="border border-blue-500 p-0 py-3">
                <span className="text-[#040c58]">
                  {activeCourse?.courseCode}.{idx + 1}
                </span>
              </td>
              <th className="bg-[#EFF3FF] border border-blue-500 p-0 py-3">
                {coAttData?.["coAttData"]?.[`CO${idx + 1}`]?.[
                  "coAttLvlFloat"
                ] ?? ""}
              </th>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO1"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO1"]?.[each]?.coPoAtt ?? ""}
              </td>

              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO2"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO2"]?.[each]?.coPoAtt ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO3"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO3"]?.[each]?.coPoAtt ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO4"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO4"]?.[each]?.coPoAtt ?? ""}
              </td>
            </tr>
          ))}

          <tr className="border border-blue-500 p-0 w-full">
            <td
              className="bg-[#EFF3FF] border border-blue-500 p-2 py-3"
              colSpan="3"
            >
              <span className="text-[#040c58]">
                Total PO Attainment SUM(PO AT)
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[0]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[1]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[2]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[3]?.["poAttvalue"] ?? ""}
              </span>
            </td>
          </tr>
        </table>

        <table
          className={`${styles["marks-dist-table"]} text-center border border-blue-500 mt-10 w-full`}
        >
          <tr className="border border-blue-500 p-0 w-full">
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO5
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO6
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO7
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO8
            </th>
          </tr>
          <tr className="border border-blue-500 p-0 w-full">
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
          </tr>

          {Object.keys(data?.PO1 ?? {}).map((each, idx) => (
            <tr className="border border-blue-500 p-0 w-full">
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO5"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO5"]?.[each]?.coPoAtt ?? ""}
              </td>

              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO6"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO6"]?.[each]?.coPoAtt ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO7"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO7"]?.[each]?.coPoAtt ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO8"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO8"]?.[each]?.coPoAtt ?? ""}
              </td>
            </tr>
          ))}

          <tr className="border border-blue-500 p-0 w-full">
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">Total PO ATT</span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[4]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[5]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[6]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[7]?.["poAttvalue"] ?? ""}
              </span>
            </td>
          </tr>
        </table>

        <table
          className={`${styles["marks-dist-table"]} text-center border border-blue-500 mt-10 w-full`}
        >
          <tr className="border border-blue-500 p-0 w-full">
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO9
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO10
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO11
            </th>
            <th
              className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
              colSpan="2"
            >
              PO12
            </th>
          </tr>
          <tr className="border border-blue-500 p-0 w-full">
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
              Weightage <br /> (W)
            </th>
            <th className="bg-[#EFF3FF] border border-blue-500 p-2">
              PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
            </th>
          </tr>

          {Object.keys(data?.PO1 ?? {}).map((each, idx) => (
            <tr className="border border-blue-500 p-0 w-full">
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO9"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO9"]?.[each]?.coPoAtt ?? ""}
              </td>

              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO10"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO10"]?.[each]?.coPoAtt ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO11"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO11"]?.[each]?.coPoAtt ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO12"]?.[each]?.weightage ?? ""}
              </td>
              <td className="border border-blue-500 p-0 py-3">
                {data?.["PO12"]?.[each]?.coPoAtt ?? ""}
              </td>
            </tr>
          ))}
          <tr className="border border-blue-500 p-0 w-full">
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">Total PO ATT</span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[8]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[9]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[10]?.["poAttvalue"] ?? ""}
              </span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]"></span>
            </td>
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">
                {psoData?.[11]?.["poAttvalue"] ?? ""}
              </span>
            </td>
          </tr>
        </table>

        {this.renderDynamicPsoHeader()}
      </div>
    );
  };

  renderDynamicPsoHeader = () => {
    const { data, psoData } = this.state;
    const ans = [];

    const keys = Object.keys(data ?? {});
    const psoKeys = keys.filter((each) => each.startsWith("PSO"));
    const tablesCount = Math.ceil(psoKeys.length / 4);

    for (let i = 0; i < tablesCount; i++) {
      ans.push(
        <table
          className={`${styles["marks-dist-table"]} text-center border border-blue-500 mt-10 w-full`}
        >
          <tr className="border border-blue-500 p-0 w-full">
            {psoKeys.slice(i * 4, 4 * (i + 1)).map((pso) => (
              <th
                className="bg-[#EFF3FF] border border-blue-500 p-0 py-1 px-1 "
                colSpan="2"
              >
                {pso}
              </th>
            ))}
          </tr>
          <tr className="border border-blue-500 p-0 w-full">
            {psoKeys.slice(i * 4, 4 * (i + 1)).map((pso) => (
              <>
                <th className="bg-[#EFF3FF] border border-blue-500 p-2 ">
                  Weightage <br /> (W)
                </th>
                <th className="bg-[#EFF3FF] border border-blue-500 p-2">
                  PO AT <br /> <span className="text-xs">(A*W/SUM(W))</span>
                </th>
              </>
            ))}
          </tr>

          {Object.keys(data?.PO1 ?? {}).map((each) => (
            <tr className="border border-blue-500 p-0 w-full">
              {psoKeys.slice(i * 4, 4 * (i + 1)).map((pso) => (
                <>
                  <td className="border border-blue-500 p-0 py-3">
                    {data?.[pso]?.[each]?.weightage ?? ""}
                  </td>
                  <td className="border border-blue-500 p-0 py-3">
                    {data?.[pso]?.[each]?.coPoAtt ?? ""}
                  </td>
                </>
              ))}
            </tr>
          ))}
          <tr className="border border-blue-500 p-0 w-full">
            <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
              <span className="text-[#040c58]">Total PO ATT</span>
            </td>
            {psoKeys.slice(i * 4, 4 * (i + 1)).map((pso, idx, arr) => (
              <>
                <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
                  <span className="text-[#040c58]">
                    {psoData?.[12 + 4 * i + idx]?.["poAttvalue"] ?? ""}
                  </span>
                </td>
                {idx < arr.length - 1 && (
                  <td className="bg-[#EFF3FF] border border-blue-500 p-2 py-3">
                    <span className="text-[#040c58]"></span>
                  </td>
                )}
              </>
            ))}
          </tr>
        </table>
      );
    }

    return ans;
  };

  render() {
    const { loader } = this.state;

    return loader ? (
      this.renderLoader()
    ) : (
      <>
        {this.renderPoPsoThroughCoScreen()}
        {this.renderPoPsoScreen()}
      </>
    );
  }
}

export default PoAttainmentTable;
